import(/* webpackMode: "eager" */ "__barrel_optimize__?names=Rating!=!/vercel/path0/node_modules/@mui/material/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-video/dist/components/video.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/difference/best-materials.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/hero/best-selling-challenger.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/hero/best-selling-corvette.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/hero/best-selling-el-camino.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/hero/best-selling-miata.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/hero/covers-grid/car-cover-home-icon.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/hero/covers-grid/floor-mat-home-icon.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/hero/covers-grid/seat-cover-front-home-icon.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/hero/covers-grid/seat-cover-full-home-icon.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/hero/trending-chevy.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/hero/trending-dodge.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/hero/trending-ford.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/hero/trending-ram.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/reviews/review1_1.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/reviews/review1_2.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/reviews/review1_5.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/reviews/review2_1.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/security/scratch-icon.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/security/security-beach.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/security/storm-icon.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/security/sun-icon.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/security/umbrella-icon.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/solutions/indoor-car-cover-picture.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/trust-banner/BBB-Rating.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/trust-banner/google-banner-2.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/trust-banner/trust-banner-background-optimized.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/trust-banner/trust-banner-bg-mobile.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/video/Thumbnail.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/hero/dropdown/HeroDropdown.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/hero/HeroImage.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/HomepageReviewCarousel.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/carousel.tsx")